
import CustomInput from "@/components/CustomInput.vue";
import CustomButton from "@/components/CustomButton.vue";
import InformationTemplate from "@/components/InformationTemplate.vue";
import AvatarImage from "@/components/AvatarImage.vue";
import MoreProfile from "./MoreProfile.vue";
import HtmlProfile from "./HtmlProfile.vue";
import { MeModule } from "@/store/modules/me";
import {
  fetchUser,
  updateUser,
  UpdateUserRequest,
} from "@/services/userService";
import { updateInstance } from "@/services/wrapper";
import { PopoutsModule } from "@/store/modules/popouts";

import { defineComponent } from "vue";
export default defineComponent({
  name: "Account",
  components: {
    CustomInput,
    CustomButton,
    AvatarImage,
    InformationTemplate,
    HtmlProfile,
    MoreProfile,
  },
  data() {
    return {
      aboutMe: null as any,
      email: "",
      username: "",
      tag: "",
      password: "",
      showNewPassword: false,
      newPassword: "",
      newPasswordConfirm: "",
      newAvatar: null as string | null,
      newBanner: null as string | null,
      requestSent: false,
      errors: {} as any,
    };
  },
  computed: {
    bannerImageUrl(): any {
      if (this.newBanner) return this.newBanner;
      if (!this.me.banner) return null;
      return process.env.VUE_APP_NERTIVIA_CDN + this.me.banner;
    },
    me(): any {
      return MeModule.user;
    },
    isConnected(): any {
      return MeModule.connected;
    },
    showPassword(): any {
      const { emailChanged, usernameChanged, tagChanged, newPasswordChanged } =
        this.changedItems;

      const othersChanged =
        emailChanged || usernameChanged || tagChanged || newPasswordChanged;

      return othersChanged || this.showNewPassword;
    },
    showSaveButton(): any {
      const {
        emailChanged,
        usernameChanged,
        tagChanged,
        newPasswordChanged,
        avatarChanged,
        bannerChanged,
      } = this.changedItems;

      return (
        emailChanged ||
        usernameChanged ||
        tagChanged ||
        newPasswordChanged ||
        avatarChanged ||
        bannerChanged
      );
    },
    changedItems(): any {
      const me = this.me;
      const emailChanged = this.email !== me.email;
      const usernameChanged = this.username !== me.username;
      const tagChanged = this.tag !== me.tag;
      const newPasswordChanged = this.newPassword.length;
      const avatarChanged = this.newAvatar?.length || false;
      const bannerChanged = this.newBanner?.length || false;
      return {
        emailChanged,
        usernameChanged,
        tagChanged,
        newPasswordChanged,
        avatarChanged,
        bannerChanged,
      };
    },
  },
  watch: {
    isConnected: {
      handler: "onConnectionChange",
    },
  },
  mounted() {
    this.resetValues();
    this.moreProfileUpdate();
  },
  methods: {
    showWelcomePopout() {
      PopoutsModule.ShowPopout({
        id: "welcome",
        component: "Welcome",
        data: {},
      });
    },
    moreProfileUpdate() {
      if (!MeModule.user.id) return;
      fetchUser(MeModule.user.id).then((user) => {
        this.aboutMe = user.user.about_me;
      });
    },
    resetValues() {
      this.email = MeModule.user.email || "";
      this.username = MeModule.user.username || "";
      this.tag = MeModule.user.tag || "";
      this.password = "";
      this.showNewPassword = false;
      this.newPassword = "";
      this.newPasswordConfirm = "";
      this.newAvatar = null;
      this.newBanner = null;
    },
    avatarChange(event: any) {
      const file: File = event.target.files[0];
      event.target.value = "";
      if (!file) return;
      const reader = new FileReader();
      reader.onloadend = (event) => {
        this.newAvatar = (event.target?.result as any) || null;
      };
      reader.readAsDataURL(file);
    },
    bannerChange(event: any) {
      const file: File = event.target.files[0];
      event.target.value = "";
      if (!file) return;
      const reader = new FileReader();
      reader.onloadend = (event) => {
        this.newBanner = (event.target?.result as any) || null;
      };
      reader.readAsDataURL(file);
    },
    relinkButton() {
      PopoutsModule.ShowPopout({
        id: "link-google-drive",
        component: "LinkGoogleDrive",
      });
    },
    update() {
      if (this.requestSent) return;
      this.errors = {};
      this.requestSent = true;
      const data: UpdateUserRequest = {};

      this.changedItems.usernameChanged &&
        (data.username = this.username.trim());
      this.changedItems.tagChanged && (data.tag = this.tag.trim());
      this.password.trim().length && (data.password = this.password.trim());
      this.changedItems.newPasswordChanged &&
        (data.new_password = this.newPassword.trim());
      this.changedItems.emailChanged && (data.email = this.email.trim());
      this.changedItems.avatarChanged && (data.avatar = this.newAvatar || "");
      this.changedItems.bannerChanged && (data.banner = this.newBanner || "");

      if (!this.showPassword) delete data["password"];

      if (data.new_password && data.new_password !== this.newPasswordConfirm) {
        this.errors["confirm_new_password"] = "Passwords do not match!";
        this.requestSent = false;
        return;
      }

      updateUser(data, this.$socket.id)
        .then((res) => {
          if (res.token) {
            localStorage["hauthid"] = res.token;
            delete res.token;
            updateInstance();
          }
          MeModule.UpdateUser(res);
          this.resetValues();
          this.requestSent = false;
        })
        .catch(async (err) => {
          if (!err.response) {
            this.errors["other"] = this.$t(
              "could-not-connect-to-server"
            ).toString();
            this.requestSent = false;
            return;
          }
          const knownErrs = [
            "username",
            "tag",
            "password",
            "new_password",
            "email",
          ];
          const { errors, message } = await err.response.json();
          if (message) {
            this.errors["other"] = message;
            this.requestSent = false;
            return;
          }
          for (let i = 0; i < errors.length; i++) {
            const error = errors[i];
            if (!knownErrs.includes(error.param)) {
              this.errors["other"] = error.msg;
              continue;
            }
            this.errors[error.param] = error.msg;
          }
          this.requestSent = false;
        });
    },
    onConnectionChange(connected: boolean) {
      if (connected) this.resetValues();
    },
  },
});
